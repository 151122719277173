// import { i18n } from '@/i18n';
import { i18n } from '@/vueI18n'
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
import IntegerField from '@/shared/fields/integer-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import StringArrayField from '@/shared/fields/string-array-field';
import BooleanField from '@/shared/fields/boolean-field';
import JsonField from '@/shared/fields/json-field';
// import IntegerRangeField from '@/shared/fields/integer-range-field';
// import ImagesField from '@/shared/fields/images-field';

function label(name) {
  return i18n(`entities.plan.fields.${name}`);
}
function enumeratorLabel(name, value) {
  return i18n(`entities.plan.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  ownerId: new StringField('ownerId', label('ownerId'), {}),
  name: new JsonField('name', label('name')),
  description: new JsonField('description', label('description')),
  type: new EnumeratorField('type', label('type'), [
    { value: 'default', label: enumeratorLabel('planType', 'default'), i18n: 'entities.plan.enumerators.planType.default' },
    { value: 'custom', label: enumeratorLabel('planType', 'custom'), i18n: 'entities.plan.enumerators.planType.custom' },
  ]),
  // numberOfDays: new IntegerField('numberOfDays', label('numberOfDays'), {}),
  numberOfDays: new EnumeratorField('numberOfDays', label('numberOfDays'), [
    { value: 1, label: enumeratorLabel('tripDays', 'oneDay'), i18n: 'entities.plan.enumerators.tripDays.oneDay' },
    { value: 2, label: enumeratorLabel('tripDays', 'twoDays'), i18n: 'entities.plan.enumerators.tripDays.twoDays' },
    { value: 3, label: enumeratorLabel('tripDays', 'ThreeDays'), i18n: 'entities.plan.enumerators.tripDays.ThreeDays' },
    { value: 4, label: enumeratorLabel('tripDays', 'FourDays'), i18n: 'entities.plan.enumerators.tripDays.FourDays' },
    { value: 5, label: enumeratorLabel('tripDays', 'FiveDays'), i18n: 'entities.plan.enumerators.tripDays.FiveDays' },
    { value: 6, label: enumeratorLabel('tripDays', 'SixDays'), i18n: 'entities.plan.enumerators.tripDays.SixDays' },
    { value: 7, label: enumeratorLabel('tripDays', 'SevenDays'), i18n: 'entities.plan.enumerators.tripDays.SevenDays' },
  ]),
  enabled: new BooleanField('disabled', label('disabled'), {
    noLabel: i18n('user.disabled'),
    yesLabel: i18n('user.enabled'),
  }),
  programs: new StringArrayField('programs', label('programs'), {}),

  // startPoint: Place
  // endPoint: Place
  // images: [JSON]
  // owner: Owner

  //#region []
    numberOfPlans: new IntegerField('numberOfPlans', label('numberOfPlans'), {}),
    cityName: new StringArrayField('cityName', label('cityName'), {}),
  //#endregion

  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
};

export class PlanModel extends GenericModel {
  static get fields() {
    return fields;
  }
}