// import { i18n } from '@/i18n';
import { i18n } from '@/vueI18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import {
  GenericModel
} from '@/shared/model/generic-model';
import IntegerField from '@/shared/fields/integer-field';
// import IntegerRangeField from '@/shared/fields/integer-range-field';
import StringField from '@/shared/fields/string-field';
import DecimalField from '@/shared/fields/decimal-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import JsonField from '@/shared/fields/json-field';
// import ImagesField from '@/shared/fields/images-field';
// import JsonField from '@/shared/fields/json-field';
// import BooleanField from '@/shared/fields/boolean-field';
// import StringArrayField from '@/shared/fields/string-array-field';
// import { CityField } from '@/modules/city/city-field';

function label(name) {
  return i18n(`entities.transaction.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.transaction.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  tranNum: new IntegerField('tranNum', label('tranNum')),
  userId: new StringField('userId', label('userId'), {}),
  payerId: new StringField('payerId', label('payerId'), {}),
  payeeId: new StringField('payeeId', label('payeeId'), {}),

  accountType: new EnumeratorField('accountType', label('accountType'), [
    { value: 'guide', label: enumeratorLabel('userType', 'guide'), i18n: `entities.transaction.enumerators.userType.guide` },
    { value: 'saryah', label: enumeratorLabel('userType', 'saryah'), i18n: `entities.transaction.enumerators.userType.saryah` },
    { value: 'client', label: enumeratorLabel('userType', 'client'), i18n: `entities.transaction.enumerators.userType.client` },
    { value: 'company', label: enumeratorLabel('userType', 'company'), i18n: `entities.transaction.enumerators.userType.company` },
  ],{}),
  payerType: new EnumeratorField('payerType', label('payerType'), [
    { value: 'guideFreelance', label: enumeratorLabel('userType', 'guide'), i18n: `entities.transaction.enumerators.userType.guide` },
    { value: 'guideCompany', label: enumeratorLabel('userType', 'guide'), i18n: `entities.transaction.enumerators.userType.guide` },
    { value: 'saryah', label: enumeratorLabel('userType', 'saryah'), i18n: `entities.transaction.enumerators.userType.saryah` },
    { value: 'client', label: enumeratorLabel('userType', 'client'), i18n: `entities.transaction.enumerators.userType.client` },
    { value: 'company', label: enumeratorLabel('userType', 'company'), i18n: `entities.transaction.enumerators.userType.company` },
  ],{}),
  payeeType: new EnumeratorField('payeeType', label('payeeType'), [
    { value: 'guideFreelance', label: enumeratorLabel('userType', 'guide'), i18n: `entities.transaction.enumerators.userType.guide` },
    { value: 'guideCompany', label: enumeratorLabel('userType', 'guide'), i18n: `entities.transaction.enumerators.userType.guide` },
    { value: 'saryah', label: enumeratorLabel('userType', 'saryah'), i18n: `entities.transaction.enumerators.userType.saryah` },
    { value: 'client', label: enumeratorLabel('userType', 'client'), i18n: `entities.transaction.enumerators.userType.client` },
    { value: 'company', label: enumeratorLabel('userType', 'company'), i18n: `entities.transaction.enumerators.userType.company` },
  ],{}),
  paymentMethod: new EnumeratorField('paymentMethod', label('paymentMethod'), [
    { value: 'cash', label: enumeratorLabel('paymentType', 'cash'), i18n: `entities.transaction.enumerators.paymentType.cash` },
    { value: 'visa', label: enumeratorLabel('paymentType', 'visa'), i18n: `entities.transaction.enumerators.paymentType.visa` },
    { value: 'wallet', label: enumeratorLabel('paymentType', 'wallet'), i18n: `entities.transaction.enumerators.paymentType.wallet` },
  ],{}),
  status: new EnumeratorField('status', label('status'), [
    { value: 'pending', label: enumeratorLabel('statusType', 'pending'), i18n: `entities.transaction.enumerators.statusType.pending` },
    { value: 'success', label: enumeratorLabel('statusType', 'success'), i18n: `entities.transaction.enumerators.statusType.success` },
    { value: 'fail', label: enumeratorLabel('statusType', 'fail'), i18n: `entities.transaction.enumerators.statusType.fail` },
    { value: 'refund', label: enumeratorLabel('statusType', 'refund'), i18n: `entities.transaction.enumerators.statusType.refund` },
  ],{}),
  type: new EnumeratorField('type', label('type'), [
    { value: 'credit', label: enumeratorLabel('transactionType', 'credit'), i18n: `entities.transaction.enumerators.transactionType.credit` },
    { value: 'debit', label: enumeratorLabel('transactionType', 'debit'), i18n: `entities.transaction.enumerators.transactionType.debit` },
    { value: 'refund', label: enumeratorLabel('transactionType', 'refund'), i18n: `entities.transaction.enumerators.transactionType.refund` },
    { value: 'withdraw', label: enumeratorLabel('transactionType', 'withdraw'), i18n: `entities.transaction.enumerators.transactionType.withdraw` },
    { value: 'deposit', label: enumeratorLabel('transactionType', 'deposit'), i18n: `entities.transaction.enumerators.transactionType.deposit` },
    { value: 'hold', label: enumeratorLabel('transactionType', 'hold'), i18n: `entities.transaction.enumerators.transactionType.hold` },
  ],{}),
  operation: new EnumeratorField('operation', label('operation'), [
    { value: 'trip', label: enumeratorLabel('operationType', 'trip'), i18n: `entities.transaction.enumerators.operationType.trip` },
    { value: 'wallet', label: enumeratorLabel('operationType', 'wallet'), i18n: `entities.transaction.enumerators.operationType.wallet` },
  ],{}),

  amount: new DecimalField('amount', label('amount'), {}),
  saryahPercentage: new DecimalField('saryahPercentage', label('saryahPercentage'), {}),
  vatPercentage: new DecimalField('vatPercentage', label('vatPercentage'), {}),
  cancellationPercentage: new DecimalField('cancellationPercentage', label('cancellationPercentage'), {}),
  
  description: new JsonField('description', label('description')),

  operationId: new StringField('operationId', label('operationId'), {}),
  trackId: new StringField('trackId', label('trackId'), {}),
  transId: new StringField('transId', label('transId'), {}),
  paymentId: new StringField('paymentId', label('paymentId'), {}),
  paymentPageUrl: new StringField('paymentPageUrl', label('paymentPageUrl'), {}),
  currencyCode: new StringField('currencyCode', label('currencyCode'), {}),
  

  dueDate: new DateTimeField('dueDate', label('dueDate')),
  createdBy: new StringField('createdBy', label('createdBy'), {}),
  updatedBy: new StringField('updatedBy', label('updatedBy'), {}),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
};

export class TransactionModel extends GenericModel {
  static get fields() {
    return fields;
  }
}