import { i18n } from '@/vueI18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import DecimalField from '@/shared/fields/decimal-field';
import IntegerField from '@/shared/fields/integer-field';
// import IntegerRangeField from '@/shared/fields/integer-range-field';
import StringField from '@/shared/fields/string-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import ImagesField from '@/shared/fields/images-field';
import JsonField from '@/shared/fields/json-field';
import StringArrayField from '@/shared/fields/string-array-field';
import BooleanField from '@/shared/fields/boolean-field';

function label(name) {
  return i18n(`entities.trip.fields.${name}`);
}
function enumeratorLabel(name, value) {
  return i18n(`entities.trip.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  tripNumber: new IntegerField('tripNumber',label('tripNumber'),{}),
  name: new JsonField('name',label('name'),{}),
  price: new JsonField('price',label('price'),{}),
  capacity: new JsonField('capacity',label('capacity'),{}),
  tripTime: new DateTimeField('tripTime', label('tripTime')),
  vatPercentage: new DecimalField('vatPercentage', label('vatPercentage'), {}),
  feesPercentage: new DecimalField('feesPercentage', label('feesPercentage'), {}),
  cancelationReason: new StringField('cancelationReason', label('cancelationReason'), {}),
  image: new ImagesField('image', label('image'), ),
  disabled: new BooleanField('disabled', label('disabled'), {}),
  planId: new StringField('planId',label('planId'),{}),
  startCityId: new StringField('startCityId', label('startCityId'), {}),
  companyId: new StringField('companyId', label('companyId'), {}),
  guidesId: new StringArrayField('guidesId',label('guidesId'),{}),
  captainId: new StringArrayField('captainId',label('captainId'),{}),
  rate: new DecimalField('rate', label('rate'), {}),

  options: new StringArrayField('options', label('options'), {}),
  reservationCount: new IntegerField('reservationCount',label('reservationCount'),{}),
  reservations: new StringArrayField('reservations',label('reservations'),{}),
  guides: new StringArrayField('guides',label('guides'),{}),
  plan: new JsonField('plan',label('plan'),{}),
  startCity: new JsonField('startCity',label('startCity'),{}),
  company: new JsonField('company',label('company'),{}),
  owner: new JsonField('owner',label('owner'),{}),

  reservationType: new EnumeratorField('reservationType', label('reservationType'), [
    { value: 'private', label: enumeratorLabel('reservationType', 'private') },
    { value: 'group', label: enumeratorLabel('reservationType', 'group') },
  ],{}),
  type: new EnumeratorField('type', label('type'), [
    { value: 'private', label: enumeratorLabel('type', 'private'), i18n: 'entities.trip.enumerators.type.private' },
    { value: 'group', label: enumeratorLabel('type', 'group'), i18n: 'entities.trip.enumerators.type.group' },
    { value: 'both', label: enumeratorLabel('type', 'both'), i18n: 'entities.trip.enumerators.type.both' },
    { value: 'car', label: enumeratorLabel('type', 'car'), i18n: 'entities.trip.enumerators.type.car' },
  ],{}),
  ownerType: new EnumeratorField('ownerType', label('ownerType'), [
    { value: 'company', label: enumeratorLabel('ownerType', 'company'), i18n: 'entities.trip.enumerators.ownerType.company' },
    { value: 'guide', label: enumeratorLabel('ownerType', 'guide'), i18n: 'entities.trip.enumerators.ownerType.guide' },
    { value: 'client', label: enumeratorLabel('ownerType', 'client'), i18n: 'entities.trip.enumerators.ownerType.client' },
  ],{}),
  status: new EnumeratorField('status', label('status'), [
    { value: 'pending', label: enumeratorLabel('status', 'pending'), i18n: 'entities.trip.enumerators.status.pending' },
    { value: 'scheduled', label: enumeratorLabel('status', 'scheduled'), i18n: 'entities.trip.enumerators.status.scheduled' },
    { value: 'started', label: enumeratorLabel('status', 'started'), i18n: 'entities.trip.enumerators.status.started' },
    { value: 'done', label: enumeratorLabel('status', 'done'), i18n: 'entities.trip.enumerators.status.done' },
    { value: 'cancelled', label: enumeratorLabel('status', 'canceled'), i18n: 'entities.trip.enumerators.status.canceled' },
  ],{}),
  paymentMethod: new EnumeratorField('paymentMethod', label('paymentMethod'), [
    { value: 'cash', label: enumeratorLabel('paymentMethod', 'cash'), i18n: 'entities.trip.enumerators.paymentMethod.cash' },
    { value: 'credit', label: enumeratorLabel('paymentMethod', 'credit'), i18n: 'entities.trip.enumerators.paymentMethod.credit' },
    { value: 'both', label: enumeratorLabel('paymentMethod', 'both'), i18n: 'entities.trip.enumerators.paymentMethod.both' },
  ]),
  
  currentPlace: new StringField('currentPlace', label('currentPlace'), {}),
  currentDay: new IntegerField('currentDay',label('currentDay'),{}),
  tripEndTime: new DateTimeField('tripEndTime', label('tripEndTime')),

  createdBy: new StringField('createdBy', label('createdBy'), {}),
  updatedBy: new StringField('updatedBy', label('updatedBy'), {}),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
};

export class TripModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
