//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import HomeChart from '@/modules/home/components/home-chart.vue';
import InprogressTripListTable from '@/modules/home/components/1-inprogress-trip-list-table.vue';
import CurrentOffersListTable from '@/modules/home/components/2-current-offers-list-table.vue';
import TransactionList from '@/modules/home/components/3-transaction-list.vue';
import ReportBox from '@/modules/home/components/report-box.vue';

export default {
  name: 'app-home-page',

  components: {
    HomeChart,
    [InprogressTripListTable.name]: InprogressTripListTable,
    [CurrentOffersListTable.name]: CurrentOffersListTable,
    [TransactionList.name]: TransactionList,
    [ReportBox.name]: ReportBox,
  },

  async mounted() {
    if (this.isMobile) {
      this.collapseMenu();
    }
    this.doFetchDashboard();
  },

  data() {
    return {
      shape: '',
      group: 'all',

      options: [
        { label: 'All', value: 'all' },
        { label: 'Company', value: 'company' },
        { label: 'Tour guide', value: 'guide' },
        { label: 'Client', value: 'client' },
      ],
    };
  },

  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      is_labtop: 'layout/is_labtop',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
      currentDate: 'home/currentDate',
      loading: 'home/loading',
      model: 'home/statistics',
    }),
    totalCompanyInThisMonth() {
      const year = this.currentDate ? this.currentDate['year'] : null
      const month = this.currentDate ? this.currentDate['month'] : null
      const companyCountObject = year && month ? this.model[year] || null : null

      let value = {
        total: 0,
        week1: 0,
        week2: 0,
        week3: 0,
        week4: 0,
        week5: 0,
      }
      if (companyCountObject) {
        Object.keys(companyCountObject).forEach(key => {
          value.total += parseInt(companyCountObject[key]) || 0
          value[key] = parseInt(companyCountObject[key]) || 0
        });
      } 
      return value
    },
    totalClientInThisMonth() {
      const year = this.currentDate ? this.currentDate['year'] : null
      const month = this.currentDate ? this.currentDate['month'] : null
      const clientCountObject = year && month ? this.model[year][month]['clientsCount'] || null : null
      let value = {
        total: 0,
        week1: 0,
        week2: 0,
        week3: 0,
        week4: 0,
        week5: 0,
      }
      if (clientCountObject) {
        Object.keys(clientCountObject).forEach(key => {
          value.total += parseInt(clientCountObject[key]) || 0
          value[key] = parseInt(clientCountObject[key]) || 0
        });
      } 

      return value
    },
    totalGuideInThisMonth() {
      const year = this.currentDate ? this.currentDate['year'] : null
      const month = this.currentDate ? this.currentDate['month'] : null
      const guideCountObject = year && month ? this.model[year][month]['freelanceGuidesCount'] || null : null
      let value = {
        total: 0,
        week1: 0,
        week2: 0,
        week3: 0,
        week4: 0,
        week5: 0,
      }
      if (guideCountObject) {
        Object.keys(guideCountObject).forEach(key => {
          value.total += parseInt(guideCountObject[key]) || 0
          value[key] = parseInt(guideCountObject[key]) || 0
        });
      } 

      return value
    },

    completedTripsConfig() {
      if (this.model['completedTripsCount'] != null) {
        return {
          type: 'doughnut',
          options: {
            legend: {
              display: false,
            },
            cutoutPercentage: 80,
          },
          data: {
            labels: [
              'Uncompleted Trips',
              'Completed Trips',
            ],
            datasets: [
              {
                data: [
                  this.model['tripsCount'] -  this.model['completedTripsCount'],
                  this.model['completedTripsCount'],
                ],
                backgroundColor: ['transparent', '#36B1BF'],
                borderWidth: 0,
                borderColor: '#fff',
              },
            ],
          },
        };
      }
      return null;
    },
    companyLineConfig() {
      if (this.totalCompanyInThisMonth) {
        return {
          type: 'line',
          options: {
            legend: {
              display: false,
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                    display: false,
                  },
                  gridLines: {
                    display: false,
                  },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    display: false,
                  },
                  gridLines: {
                    display: false,
                  },
                },
              ],
            },
          },
          data: {
            labels: [
              'Week 1',
              'Week 2',
              'Week 3',
              'Week 4',
              'Week 5',
            ],
            datasets: [
              {
                label: '# of new companies',
                data: [
                  this.totalCompanyInThisMonth['week1'],
                  this.totalCompanyInThisMonth['week2'],
                  this.totalCompanyInThisMonth['week3'],
                  this.totalCompanyInThisMonth['week4'],
                  this.totalCompanyInThisMonth['week5'],
                ],
                borderWidth: 2,
                borderColor: '#36B1BF',
                backgroundColor: 'transparent',
                pointBorderColor: 'transparent',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
              },
            ],
          },
        };
      }
      return null;
    },
    clientLineConfig() {
      if (this.totalClientInThisMonth) {
        return {
          type: 'line',
          options: {
            legend: {
              display: false,
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                    display: false,
                  },
                  gridLines: {
                    display: false,
                  },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    display: false,
                  },
                  gridLines: {
                    display: false,
                  },
                },
              ],
            },
          },
          data: {
            labels: [
              'Week 1',
              'Week 2',
              'Week 3',
              'Week 4',
              'Week 5',
            ],
            datasets: [
              {
                label: '# of new clients',
                data: [
                  this.totalClientInThisMonth['week1'],
                  this.totalClientInThisMonth['week2'],
                  this.totalClientInThisMonth['week3'],
                  this.totalClientInThisMonth['week4'],
                  this.totalClientInThisMonth['week5'],
                ],
                borderWidth: 2,
                borderColor: '#36B1BF',
                backgroundColor: 'transparent',
                pointBorderColor: 'transparent',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
              },
            ],
          },
        };
      }
      return null;
    },
    guideLineConfig() {
      if (this.totalGuideInThisMonth) {
        return {
          type: 'line',
          options: {
            legend: {
              display: false,
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                    display: false,
                  },
                  gridLines: {
                    display: false,
                  },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    display: false,
                  },
                  gridLines: {
                    display: false,
                  },
                },
              ],
            },
          },
          data: {
            labels: [
              'Week 1',
              'Week 2',
              'Week 3',
              'Week 4',
            ],
            datasets: [
              {
                label: '# of new guides',
                data: [
                  this.totalGuideInThisMonth['week1'],
                  this.totalGuideInThisMonth['week2'],
                  this.totalGuideInThisMonth['week3'],
                  this.totalGuideInThisMonth['week4'],
                  this.totalGuideInThisMonth['week5'],
                ],
                borderWidth: 2,
                borderColor: '#36B1BF',
                backgroundColor: 'transparent',
                pointBorderColor: 'transparent',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
              },
            ],
          },
        };
      }
      return null;
    },
    GuideRate() {
      const freelanceGuidesCount = this.model['freelanceGuidesCount'] || 0
      const companiesCount = this.model['companiesCount'] || 0
      return {
        type: 'pie',
        options: {
          responsive: true,
          legend: {
            display: false,
          },
        },
        data: {
          labels: [
            'Tourguide freelancer',
            'companies',
          ],
          datasets: [
            {
              data: [freelanceGuidesCount, companiesCount],
              backgroundColor: ['#FFC726', '#8CE1EB'],
              hoverBackgroundColor: ['#FFC726', '#8CE1EB'],
              borderWidth: 5,
              borderColor: '#fff',
            },
          ],
        },
      }
    },
    statisticsConfig() {
      let chart = {
        type: 'line',
        options: {
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontSize: '12',
                  fontColor: '#777777',
                },
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  fontSize: '12',
                  fontColor: '#777777',
                  beginAtZero: true,
                  callback: function(value) {
                    return value * 100 + ' # User';
                  },
                },
                gridLines: {
                  color: '#D8D8D8',
                  zeroLineColor: '#D8D8D8',
                  borderDash: [2, 2],
                  zeroLineBorderDash: [2, 2],
                  drawBorder: false,
                },
              },
            ],
          },
        },
      };

      let data = {
        labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'],
        datasets: [],
      };

      const client = {
        label: '# of Clients',
        data: [
          this.totalClientInThisMonth['week1'],
          this.totalClientInThisMonth['week2'],
          this.totalClientInThisMonth['week3'],
          this.totalClientInThisMonth['week4'],
          this.totalClientInThisMonth['week5'],
        ],
        borderWidth: 2,
        borderColor: '#B2DF8A',
        backgroundColor: 'transparent',
        pointBorderColor: '#B2DF8A',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
      };
      const company = {
        label: '# of Company',
        data: [
          this.totalCompanyInThisMonth['week1'],
          this.totalCompanyInThisMonth['week2'],
          this.totalCompanyInThisMonth['week3'],
          this.totalCompanyInThisMonth['week4'],
          this.totalCompanyInThisMonth['week5'],
        ],
        borderWidth: 2,
        borderDash: [2, 2],
        borderColor: '#A6CEE3',
        backgroundColor: 'transparent',
        pointBorderColor: '#A6CEE3',
      };
      const guide = {
        label: '# of Tourguide',
        data: [
          this.totalGuideInThisMonth['week1'],
          this.totalGuideInThisMonth['week2'],
          this.totalGuideInThisMonth['week3'],
          this.totalGuideInThisMonth['week4'],
          this.totalGuideInThisMonth['week5'],
        ],
        borderWidth: 2,
        borderColor: '#FFB900',
        backgroundColor: 'transparent',
        pointBorderColor: '#FFB900',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
      };

      switch (this.group) {
        case 'all':
          data.datasets = [client, company, guide];
          break;
        case 'client':
          data.datasets = [client];
          break;
        case 'company':
          data.datasets = [company];
          break;
        case 'guide':
          data.datasets = [guide];
          break;
        default:
          data.datasets = [client, company, guide];
          break;
      }
      chart['data'] = data;
      return chart;
    },
  },

  methods: {
    ...mapActions({
      collapseMenu: 'layout/collapseMenu',
      doFetchDashboard: 'home/doFetchDashboard',
      doFetchInProgressTrips: 'trip/list/doFetchInProgressTrips',
      doFetchMyRecentTransactions: 'transaction/list/doFetchMyRecentTransactions'
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    async reloadData() {
      this.doFetchDashboard();
      this.$refs.transaction.doFetch();
      this.$refs.inprogressTripTable.doFetch();
    }
  },
};
