//
//
//
//
//
//
//

import uuid from 'uuid/v4';
import Chart from 'chart.js';
// import cash from "cash-dom";

export default {
  // name: 'app-home-chart',

  props: {
    // type: {
    //   type: String,
    //   required: true,
    //   default: 'line',
    //   validator: value => {
    //     return ['line', 'pie', 'doughnut', 'bar'].indexOf(value) !== -1
    //   }
    // },
    // data: {
    //   type: Object,
    //   required: true,
    //   default: () => ({})
    // },
    // options: {
    //   type: Object,
    //   default: () => ({})
    // },
    config: {
      type: Object,
      // default: {},
    },
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
    // refKey: {
    //   type: String,
    //   default: null
    // }
  },

  data() {
    return {
      id: uuid(),
      chart: null,
    };
  },
  computed: {
    chartHeight() {
      return this.height > 0 ? this.height : null
    },
    chartWidth() {
      return this.width > 0 ? this.width : null
    }
  },
  mounted() {
    const ctx = document.getElementById(this.id);
    new Chart(ctx, this.config);

    // if (this.width > 0) {
    //   cash(this.$refs.chartRef.value).attr({
    //     width: this.width
    //   })
    // }

    // if (this.height > 0) {
    //   cash(this.$refs.chartRef.value).attr({
    //     height: this.height
    //   })
    // }

  },
  methods: {
    // addData(chart, label, data) {
    //   console.log('Label = ', label);
    //   // chart.data.labels.push(label);
    //   chart.data.datasets.forEach((dataset) => {
    //       dataset.data.push(data);
    //   });

    //   chart.update();
    // },

    // removeData(chart) {
    //   chart.data.labels.pop();
    //   chart.data.datasets.forEach((dataset) => {
    //       dataset.data.pop();
    //   });
    //   chart.update();
    // },
  },
  watch: {
    config(newVal) {
      console.log('Chart change :');
      console.log(newVal);

      const ctx = document.getElementById(this.id);
      new Chart(ctx, newVal);
      // this.chart.update();
    }
  },
};
