//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
// import { i18n } from '@/vueI18n';
// import i18n from '@/vueI18n';
import moment from 'moment';

import TransactionCardLoading from '@/modules/home/components/transaction-card-loading.vue'
import TransactionCard from '@/modules/home/components/transaction-card.vue'

// import firebase from 'firebase/compat/app';
// import 'firebase/compat/firestore'

// import { TransactionPermissions } from '@/modules/transaction/transaction-permissions';
import { TransactionModel } from '@/modules/transaction/transaction-model';
const { fields } = TransactionModel;

export default {
  name: 'app-transaction-list',

  components: {
    [TransactionCardLoading.name]: TransactionCardLoading,
    [TransactionCard.name]: TransactionCard,
  },

  mounted() {
    this.doFetch()
  },
  // firestore() {
  //   return {
  //     transactions: firebase.firestore().collection('transaction').orderBy('createdAt', 'desc').limit(5),
  //   }
  // },

  data() {
    return {
      selected_ID: '',
      transactions: [],
    }
  },
  computed: {
    ...mapGetters({
      rows: 'transaction/list/recentTransaction',   
      loading: 'transaction/list/recentTransactionLoading',
      // pagination: 'transaction/list/pagination',
      // destroyLoading: 'transaction/destroy/loading',

      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    fields() {
      return fields;
    },
  },

  methods: {
    ...mapActions({
      doFetchMyRecentTransactions: 'transaction/list/doFetchMyRecentTransactions'
    }),
    i18n(key, args) {
      // return i18n(key, args);
      return this.$t(key, args);
    },
    presenter(row, fieldName) {
      return TransactionModel.presenter(row, fieldName);
    },
    presenterMap(row, fieldName) {
      const val = TransactionModel.presenter(row, fieldName);
      return val[this.language]
    },
    presenterDate(row, fieldName) {
      return moment(TransactionModel.presenter(row, fieldName)).locale(this.language).format("D MMM, YYYY"); 
    },
    presenterDay(row, fieldName) {
      return moment(TransactionModel.presenter(row, fieldName)).locale(this.language).format("dddd");
    },
    presenterTime(row, fieldName) {
      let now = moment().format('YYYY/MM/DD');
      let time = TransactionModel.presenter(row, fieldName)
      let date =  `${now} ${time}`;
      return moment(date).locale(this.language).format("hh:mm a"); 
    },
    async doFetch() {
      this.doFetchMyRecentTransactions()
    }
  },
};
