//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { i18n } from '@/vueI18n';
import moment from 'moment';
import _ from 'lodash';

// import firebase from 'firebase/compat/app';
// import 'firebase/compat/firestore'

export default {
  name: 'app-transaction-card',

  props: {
    value: {
      type: Object,
      required: true,
    } 
  },
  // firestore() {
  //   return {
  //     user: firebase.firestore().collection('user').doc(this.value.createdBy),
  //   }
  // },

  data() {
    return {
      dialogVisible: false,
      modalVisible: false,
      model: null,
      transaction: undefined,
      dialog: false,
      // user: undefined,
      user: this.value.payee,
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    amountColor() {
      return this.value.type == 'credit' 
        ? { color: '#23C045' }
        : { color: '#F14336' }
    },
    // amountColor() {
    //   return this.value.payeeType == 'saryah' 
    //     ? { color: '#23C045' }
    //     : { color: '#F14336' }
    // },
    userAvatar() {
      const defaultAvatar = '/images/avatars/user-avatar.svg'
      let avatar = null
      if (this.user) {
        if (this.user.avatar) {
          if (_.isString(this.user.avatar)) {
            avatar = this.user.avatar
          } else if (_.isObject(this.user.avatar)) {
            avatar = this.user.avatar?.publicUrl ?? defaultAvatar
          }
        } 
        else if (this.user.avatars && this.user.avatars.length) {
          if (_.isString(this.user.avatars[0])) {
            avatar = this.user.avatars?.[0] ?? defaultAvatar
          } else if (_.isObject(this.user.avatars[0])) {
            avatar = this.user.avatars?.[0]?.publicUrl ?? defaultAvatar
          }
        } 
      }
      
      return avatar && avatar !== "" ? avatar : defaultAvatar
    },
  },
  methods: {
    ...mapActions({

    }),
    i18n(code, args) {
      return i18n(code, args);
    },
    presenterDate(val) {
      return moment(val).locale(this.currentLanguageCode).format("D MMM, YYYY"); 
    },
  },
};
