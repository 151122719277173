//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { TripPermissions } from '@/modules/trip/trip-permissions';
// import { i18n, getLanguageCode } from '@/i18n';
// import { i18n } from '@/vueI18n';
// import i18n from '@/vueI18n';
import moment from 'moment';

import { TripModel } from '@/modules/trip/trip-model';
const { fields } = TripModel;

export default {
  name: 'app-inprogress-trip-list-table',

  async mounted() {
    this.doFetch()
    // this.doMountTable(this.$refs.table);
  },

  data() {
    return {
      selected_ID: '',
      Pagination: {
        rowsPerPage: 10,
      },
      columns: [
        {
          name: 'tripNumber',
          field: 'tripNumber',
          label: 'entities.trip.fields.tripNumber',
          align: 'center',
        },
        {
          name: 'name',
          field: 'name',
          label: 'entities.trip.fields.name',
          align: 'center',
        },
        {
          name: 'startCity',
          field: 'startCity',
          label: 'entities.trip.fields.city',
          align: 'center',
        },
        {
          name: 'ownerType',
          field: 'ownerType',
          label: 'entities.trip.fields.ownerType',
          align: 'center',
        },
        {
          name: 'owner',
          field: 'owner',
          label: 'entities.trip.fields.owner',
          align: 'center',
        },
        {
          name: 'paymentMethod',
          field: 'paymentMethod',
          label: 'entities.trip.fields.paymentMethod',
          align: 'center',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      rows: 'trip/list/rows',
      count: 'trip/list/count',
      loading: 'trip/list/loading',
      pagination: 'trip/list/pagination',
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    hasPermissionToEdit() {
      return new TripPermissions(this.currentUser).edit;
    },
    fields() {
      return fields;
    },
  },

  methods: {
    ...mapActions({
      doMountTable: 'trip/list/doMountTable',
      doFetchInProgressTrips: 'trip/list/doFetch',
      // doFetchInProgressTrips: 'home/doFetchInProgressTrips',
      // doFetchInProgressTrips: 'trip/list/doFetchInProgressTrips'
    }),
    i18n(key, args) {
      // return i18n(code);
      return this.$t(key, args);
    },
    presenter(row, fieldName) {
      const val = row[fieldName]
      switch (fieldName) {
        case 'tripNumber': 
          return val 
            ? this.currentLanguageCode == 'ar' ? `${val} #` : `# ${val}`
            : '___'
        case 'name':
          return val ? val[this.currentLanguageCode] : 'ـــ' 
        case 'startCity':
          return val && val['name'] ? val.name[this.currentLanguageCode] : 'ـــ' 
        case 'owner':
          return val && val['name'] ? val.name[this.currentLanguageCode] : 'ـــ' 
        case 'paymentMethod':
          return val == 'both' ? 'Cash & Credit' : val || 'ـــ' 
        default:
          return val;
      }
      // return TripModel.presenter(row, fieldName);
    },
    presenterMap(row, fieldName) {
      const val = TripModel.presenter(row, fieldName);
      return val[this.language];
    },
    presenterDate(row, fieldName) {
      return moment(TripModel.presenter(row, fieldName)).locale(this.language).format('D MMM, YYYY');
    },
    presenterDay(row, fieldName) {
      return moment(TripModel.presenter(row, fieldName)).locale(this.language).format('dddd');
    },
    presenterTime(row, fieldName) {
      let now = moment().format('YYYY/MM/DD');
      let time = TripModel.presenter(row, fieldName);
      let date = `${now} ${time}`;
      return moment(date).locale(this.language).format('hh:mm a');
    },
    async doFetch() {
      this.doFetchInProgressTrips({
        filter: {
          status: ['started']
        },
        orderBy: "createdAt",
        pagination: {
          sortBy: "desc",
          limit: this.Pagination.rowsPerPage
        }
      })
    },
  },
};
